import { dftq_platform_languages } from "../config/deftiq";

export default {
  install: (app, options) => {
    // inject a globally available $translate() method
    //const languages = {nl: true, en: true,es: true, de: true,pt:true,tr: true,bg:true,fr: true}
    app.config.globalProperties.$translate = (
      key,
      injectables = {},
      preferred_language = null
    ) => {
      //double state since also stored in localstorage
      const getTranslatedKey = (language, key) => {
        let browserLanguage = language; //window.navigator.language.substring(0,2);
        browserLanguage =
          browserLanguage in dftq_platform_languages ? browserLanguage : "nl";
        key = `${browserLanguage}.${key}`;
        let result = key.split(".").reduce((o, i) => {
          if (o) return o[i];
        }, options);

        return result;
      };

      let result = getTranslatedKey(
        preferred_language == null
          ? app.config.globalProperties.$store.state.language
          : preferred_language,
        key
      );

      if (result == null) {
        //nu een error zichtbaar in de console als een sleutel onbekend is
        console.error(`Translation Error: the key ${key} is not defined.`);

        //Fallback to English in case key is not defined.
        result = getTranslatedKey("en", key);

        //Also no english then fallback to NL.
        if (result == null) {
          result = getTranslatedKey("nl", key);
        }
      }

      if (Object.keys(injectables).length > 0) {
        for (key of Object.keys(injectables)) {
          result = result.replace(`{${key}}`, injectables[key]);
        }
      }

      return result;
    };
  },
};

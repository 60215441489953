<template>
  <div
    class="tw-bg-red-900 tw-fixed tw-bottom-0 tw-right-0 tw-left-0 tw-flex tw-justify-between"
    v-if="visible"
  >
    <p class="tw-p-3 tw-mb-0 tw-text-white tw-font-medium md:tw-text-base">
      {{ message }} -
      <a
        class="tw-text-white hover:tw-text-gray-200"
        href="https://deftiq.statuspage.io"
        target="_blank"
        >more info</a
      >
    </p>
    <svg
      @click="visible = false"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="tw-w-6 tw-h-6 tw-text-white tw-mt-3 tw-mr-2 tw-shrink-0"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "IssueWarning",
  data() {
    return {
      message: "",
      visible: false,
    };
  },
  emits: [],
  props: {},
  computed: {
    incidentPresent: function () {
      return this.$store.state.user.incident != null;
    },
  },
  watch: {
    incidentPresent: function () {
      if (this.incidentPresent) {
        this.visible = true;
        this.message = this.$store.state.user.incident.english_message;
        if (this.$store.state.language == "nl") {
          this.message = this.$store.state.user.incident.dutch_message;
        }
      } else {
        this.visible = false;
      }
    },
  },
  methods: {},
};
</script>
<style scoped></style>

<template>
  <div class="dftq-form-group">
    <input
      :value="modelValue"
      class="uk-input dftq-input"
      :class="{ 'dftq-input-wrong': !valid }"
      :id="name"
      :type="type"
      placeholder=" "
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <label class="dftq-placeholder" for="company_name">{{ name }}</label>
    <label v-show="!valid" class="error-text">{{ invalidMessage }}</label>
  </div>
</template>

<script>


export default {
  name: "StandardInput",
  data: function () {
    return {

    };
  },
  props: {
    modelValue: String,
    type: {
      type: String,
      default: "text",
    },
    name: String,
    valid: {
      type: Boolean,
      default: true,
    },
    invalidMessage: {
      type: String,
      default: "De ingevulde waarde voldoet niet aan de eisen.",
    },
    disabled: {
      type: Boolean,
      default: false, 
    }
  },
  emits: ["update:modelValue"],

};
</script>

<style scoped></style>

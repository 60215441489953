let Config = {
  //baseURL: "http://localhost:7000/public",
   baseURL: process.env.VUE_APP_BASE_API_URL,
 // baseURL: "https://test-api.deftiq.com",
   baseLmsUrl: process.env.VUE_APP_BASE_LMS_URL,
  // baseLmsUrl: "http://localhost:8000",
  token: "", 
  //token: "", 
  

  setToken: function(token){
    this.token = token; 
  }
}



export default Config; 
<template>
  <div v-if="visible" id="popup-container">
    <div id="dark-overlay"></div>
    <div
      id="loginbox"
      class="uk-card uk-card-default uk-card-body full uk-margin-auto uk-padding"
    >
      <svg
        v-if="!memberstack_flow"
        @click="exitPasswordReset"
        class="tw-w-6 tw-h-6 tw-absolute tw-right-2 tw-top-2 tw-text-gray-400 tw-cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
      <h4
        class="uk-margin-remove-top dftq-font-bold uk-padding-small uk-padding-remove-right uk-padding-remove-bottom uk-padding-remove-left"
      >
        {{ headerText }}
      </h4>
      <div v-show="currentStep == 0">
        <p class="uk-margin-small">
          {{ $translate("password_reset.instructions") }}
        </p>
        <p class="uk-text-danger" v-if="blocked">
          {{ $translate("password_reset.no_attempts") }}
        </p>
        <div class="uk-margin-top dftq-form-group">
          <input
            class="uk-input dftq-input"
            id="email"
            v-model="user.email"
            v-on:keypress.enter="nextStep"
            type="email"
            :class="{ 'dftq-input-wrong': !correct_inputs.email }"
            placeholder=" "
          />
          <label class="dftq-placeholder" for="email">{{
            $translate("email")
          }}</label>
          <label
            v-show="!correct_inputs.email && email_exists"
            class="error-text"
            >{{ $translate("password_reset.incorrect_email") }}</label
          >
          <label
            v-show="!correct_inputs.email && !email_exists"
            class="error-text"
            >{{ $translate("password_reset.unknown_account") }}</label
          >
        </div>
      </div>

      <div v-if="currentStep == 1">
        <p v-if="memberstack_flow">
          {{ $translate("password_reset.safety_update") }}
        </p>
        <p class="uk-margin-small">
          <b>{{ $translate("password_reset.fill_in_code_below") }}</b
          ><br />
          {{ $translate("password_reset.fill_in_code.first") }}
          {{ this.user.email }}
          {{ $translate("password_reset.fill_in_code.second") }}
        </p>
        <p class="uk-text-danger" v-if="blocked">
          {{ $translate("password_reset.no_attempts") }}
        </p>
        <div class="uk-margin-top dftq-form-group">
          <input
            class="uk-input dftq-input"
            id="email_code"
            v-model.number="user.email_code"
            @keypress="validatePin"
            v-on:keypress.enter="nextStep"
            maxlength="6"
            type="number"
            :class="{ 'dftq-input-wrong': !correct_inputs.email_code }"
            placeholder=" "
          />
          <label class="dftq-placeholder" for="email_code">{{
            $translate("password_reset.code")
          }}</label>
          <label v-show="!correct_inputs.email_code" class="error-text">{{
            $translate("password_reset.incorrect_code")
          }}</label>
        </div>
      </div>

      <div v-if="currentStep == 2">
        <p class="uk-margin-small" v-if="memberstack_flow">
          {{ $translate("password_reset.extra_security_check") }}
        </p>
        <p v-else class="uk-margin-small">
          {{ $translate("password_reset.fill_in_new_password_below") }}
        </p>

        <form autocomplete="off">
          <div class="uk-margin-medium-top dftq-form-group">
            <input
              class="uk-input dftq-input"
              id="passy"
              autocomplete="new-password"
              v-model="user.password"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.password }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="passy">{{
              $translate("password")
            }}</label>
            <label v-show="!correct_inputs.password" class="error-text">{{
              $translate("password_reset.password_minimum")
            }}</label>
          </div>
          <div class="uk-margin-medium-top dftq-form-group">
            <input
              class="uk-input dftq-input"
              id="confpass"
              autocomplete="new-password"
              v-model="user.confirm_password"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.confirm_password }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="confpass">{{
              $translate("password_reset.repeat_password")
            }}</label>
            <label
              v-show="!correct_inputs.confirm_password"
              class="error-text"
              >{{ $translate("password_reset.passwords_dont_match") }}</label
            >
          </div>
        </form>
      </div>

      <button
        class="uk-button uk-button-primary uk-margin-small-bottom uk-width-1-1 dftq-button uk-margin-medium-top"
        @click="nextStep"
      >
        {{ buttonText }}
      </button>
      <!--
      <a v-if="currentStep==0" class = "uk-text-muted uk-float-right uk-margin-top" @click="currentStep=1"> Al een code ontvangen?</a>
      <a v-else class = "uk-text-muted uk-float-right uk-margin-top"  @click="currentStep=0">Nog geen code ontvangen?</a>
    --></div>
  </div>
</template>

<script>
import Config from "../config/config.js";
import { useToast } from "vue-toastification";
import { prepareLogin } from "../config/deftiq.js";
export default {
  name: "PasswordResetForm",
  data() {
    return {
      user: {
        email: "",
        password: "",
        email_code: "",
        confirm_password: "",
      },
      correct_inputs: {
        email: true,
        password: true,
        email_code: true,
        confirm_password: true,
      },
      visible: false,
      currentStep: 0,
      memberstack_flow: false,
      email_exists: true,
      blocked: false,
    };
  },
  computed: {
    headerText: function () {
      let text = "";
      switch (this.currentStep) {
        case 0:
          text = this.$translate("password_reset.change_password");
          break;
        case 1:
          text = !this.memberstack_flow
            ? this.$translate("password_reset.check_email")
            : this.$translate("password_reset.cybersecurity_update");
          break;
        case 2:
          text = this.$translate("password_reset.new_password");
          break;
      }
      return text;
    },
    buttonText: function () {
      //case 0 should display volgende or registreer depending on presence of selected courses with deelnemer autorisatie
      let text = "";

      switch (this.currentStep) {
        case 0:
          text = this.$translate("password_reset.send_reset_email");
          break;
        case 1:
          text = this.$translate("next");
          break;
        case 2:
          text = this.$translate("password_reset.change_your_password");
          break;
      }
      return text;
    },
    isVerified() {
      return this.$store.getters.isVerified;
    },
  },
  watch: {
    "$store.state.password_reset": function () {
      if (this.$store.state.password_reset) {
        this.visible = true;

        if (this.$store.state.memberstack_reset_email != "") {
          this.user.email = this.$store.state.memberstack_reset_email;
          this.currentStep = 1;
          this.memberstack_flow = true;
          this.verifyEmail();
        }
      }
    },
  },
  beforeMount() {},
  methods: {
    exitPasswordReset: function () {
      this.$store.commit("resetPassword", {
        state: false,
        memberstack_email: "",
      });
      this.visible = false;
    },
    nextStep: function () {
      switch (this.currentStep) {
        case 0:
          this.verifyEmail();
          break;
        case 1:
          this.verifyEmailCode();
          break;
        case 2:
          this.resetPassword();
          break;
      }
    },

    validatePin: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    validateInputs: function () {
      this.correct_inputs.password = this.user.password.length >= 8;
      this.correct_inputs.confirm_password =
        this.user.password == this.user.confirm_password;
      return Object.values(this.correct_inputs).every((e) => e === true);
    },
    verifyEmail: function () {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.correct_inputs.email = re.test(this.user.email);
      if (!this.correct_inputs.email) {
        return;
      }

      let params = new FormData();

      params.append("email", this.user.email);
      fetch(`${Config.baseURL}/public/auth/verify/email`, {
        method: "POST",
        body: params,
      })
        .then((response) => response.json())
        .then((data) => {
          const toast = useToast();
          if (data.status == "good") {
            this.currentStep = 1;
            this.blocked = false;
            this.email_exists = true;
          } else {
            if (data.message == "blocked") {
              this.blocked = true;
              toast.error(this.$translate("password_reset.no_attempts_left"));
            } else {
              this.correct_inputs.email = false;
              this.email_exists = false;
            }
          }
        });
    },
    verifyEmailCode: function () {
      let params = new FormData();
      params.append("email", this.user.email);
      params.append("code", this.user.email_code);
      fetch(`${Config.baseURL}/public/auth/email/verify/code`, {
        method: "POST",
        body: params,
      })
        .then((response) => response.json())
        .then((data) => {
          const toast = useToast();
          if (data.status == "good") {
            this.currentStep = 2;
            this.blocked = false;
            this.correct_inputs.email_code = true;
          } else {
            this.correct_inputs.email_code = false;
            if (data.message == "blocked") {
              this.blocked = true;
              toast.error(this.$translate("password_reset.no_attempts_left"));
            }
          }
        });
    },
    login: function () {
      let params = new FormData();
      //validate the email and password
      params.append("email", this.user.email);
      params.append("password", this.user.password);

      fetch(`${Config.baseURL}/public/auth/login/`, {
        method: "POST",
        body: params,
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          //probably good to use store to check status of cookie instead of both
          prepareLogin(data.expires_at, data.role);
          this.$store.commit("setLoggedin", true);
          this.$store.dispatch("fetchCsrfToken");
          if (data.role == "leerling") {
            this.$router.push("/deelnemer/trainingen");
          } else {
            this.$router.push("/dashboard");
          }
        });
    },
    resetPassword: function () {
      if (!this.validateInputs()) {
        return;
      }

      let params = new FormData();
      params.append("email", this.user.email);
      params.append("password", this.user.password);
      params.append("code", this.user.email_code);
      fetch(`${Config.baseURL}/public/auth/reset/password`, {
        method: "POST",
        body: params,
      })
        .then((response) => response.json())
        .then((data) => {
          const toast = useToast();
          if (data.status == "good") {
            this.visible = 0;
            this.$store.commit("resetPassword", {
              state: false,
              memberstack_email: "",
            });

            toast(this.$translate("password_reset.password_changed"));
            this.login();
          } else {
            toast.error(this.$translate("password_reset.something_went_wrong"));
          }
        });
    },
  },
};
</script>

<style scoped>
#dark-overlay {
  background-color: black;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0.4;
}

#close-icon {
  padding: 4px;
  cursor: pointer;
}

#loginbox {
  box-shadow: 0px 0px 10px #00000012;
  position: absolute;
  left: 35%;
  right: 35%;
  width: 30%;
  top: 160px;

  border-radius: 4px;
  max-width: 412px;
  z-index: 999;
}

@media only screen and (max-width: 1320px) {
  #loginbox {
    width: 100%;
    left: 0px;
    right: 20px;
  }
}
</style>

<template>
  <div
    class="tw-mt-[280px] tw-text-center tw-relative tw-text-xs tw-z-0 tw-font-medium"
    id="footer-copyright"
  >
    <p>COPYRIGHT DEFTIQ</p>
  </div>
</template>

<script>
export default {
  name: "DeftiqFooter",
  data: function () {
    return {};
  },
  beforeMount() {},
  mounted: function () {},
  methods: {},
};
</script>

<style scoped>
@media only screen and (min-height: 1000px) {
  #footer-copyright {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
  }
}
</style>

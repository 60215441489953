import { createStore } from "vuex";
import Config from "./config.js";
import { useToast } from "vue-toastification";
// Create a new store instance.
const store = createStore({
  state() {
    return {
      user: {},
      logged_in: false, //cookie used to retain state
      tutorial: false,
      password_reset: false,
      memberstack_reset_email: "",
      csrf_token: "",
      company_logo: "",
      language:
        localStorage.getItem("dftq-lang") != null
          ? localStorage.getItem("dftq-lang")
          : "nl",

      navBarVisibility: {
        topHidden: false,
        bottomHidden: false,
      },
      reportPayload: {},
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setCsrfToken(state, token) {
      state.csrf_token = token;
    },
    setLoggedin(state, payload) {
      state.logged_in = payload;
    },
    setMobileNavBarVisibility(state, payload) {
      state.navBarVisibility.topHidden = payload.topHidden;
      state.navBarVisibility.bottomHidden = payload.bottomHidden;
    },
    updateLanguage(state, language) {
      state.language = language;
    },
    setCompanyLogo(state, payload) {
      state.company_logo = payload.company_logo;
    },
    updateUserField(state, payload) {
      state.user[payload.field] = payload.value;
    },
    startBeheerderTour(state, value) {
      state.tutorial = value;
    },
    resetPassword(state, value) {
      state.password_reset = value.state;
      state.memberstack_reset_email = value.memberstack_email;
    },
    setReportPayload(state, payload) {
      state.reportPayload = payload;
    },
  },
  actions: {
    //identify user
    identifyUser({ commit }, user) {
      commit("setUser", user);
    },
    fetchCsrfToken({ commit, dispatch }) {
      fetch(`${Config.baseURL}/public/auth/csrf/`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "good") {
            commit("setCsrfToken", data.csrf_token);

            try {
              const channel = new BroadcastChannel("deftiq-exchange");
              channel.postMessage({
                event: "csrf",
                csrf_token: data.csrf_token,
                id: sessionStorage.getItem("window_identifier"),
              });
              channel.close();
            } catch (error) {
              const toast = useToast();
              toast.warning(
                "Met uw huidige browser kunt u de app maar op 1 tabblad gebruiken."
              );
            }
          } else {
            //problem no CSRF token then invalid login
            console.log("no token retrieved");
            dispatch("fetchCsrfToken");
          }
        });
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    isLoggedIn: (state) => {
      return state.logged_in;
    },
    isAdmin: (state) => {
      return (
        state.user.user_role == "admin" || state.user.user_role == "mini_admin"
      );
    },
    isMiniAdmin: (state) => {
      return state.user.user_role == "mini_admin";
    },
    isVerified: (state) => {
      return state.user.verify_email_code == null;
    },
    reportPayload: (state) => {
      return state.reportPayload;
    },
  },
});

export default store;

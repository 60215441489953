<template>
  <div class="darker-overlay"></div>

  <div id="mobile-popup">
    <slot />
  </div>
</template>

<script>
//import Config from "../config/config.js";

//import { useToast } from "vue-toastification";

export default {
  name: "MobilePopup",
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created() {},

  methods: {},
};
</script>

<style scoped>
#mobile-popup {
  display: none;
}

@media only screen and (max-width: 1000px) {
  #mobile-popup {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: block;
    background-color: white;
    z-index: 10;
    padding: 24px;
  }

  .darker-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(236, 240, 241, 0.5);
    z-index: 1;
  }
}
</style>

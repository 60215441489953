<template>
  <nav-bar></nav-bar>

  <router-view />

  <feedback-button />
  <register-popup-form />
  <development-tip />

  <issue-warning />
</template>

<script>
// @ is an alias to /src
import NavBar from "./components/NavBar.vue";
import Config from "./config/config.js";
import { getCookie } from "./config/deftiq.js";

import FeedbackButton from "./components/FeedbackButton.vue";
import RegisterPopupForm from "./components/RegisterPopupForm.vue";
import IssueWarning from "./components/general/IssueWarning.vue";

import DevelopmentTip from "./components/general/DevelopmentTip.vue";

export default {
  name: "App",
  components: {
    NavBar,
    DevelopmentTip,
    FeedbackButton,
    RegisterPopupForm,

    IssueWarning,
  },
  data: function () {
    return {
      scrollY: 200,
    };
  },
  beforeMount() {
    this.checkRefresh();

    if (getCookie("logged_in") == "1") {
      this.$store.dispatch("fetchCsrfToken");
    }
  },
  created() {
    try {
      //listen to the broadcast channel to see if other windows and tabs are opened.
      const channel = new BroadcastChannel("deftiq-exchange");
      channel.onmessage = (e) => {
        if (getCookie("logged_in") == "1") {
          if (
            e.data.event == "csrf" &&
            e.data.id != sessionStorage.getItem("window_identifier")
          ) {
            this.$store.commit("setCsrfToken", e.data.csrf_token);
          }
        }
      };
    } catch (error) {
      console.log("browser does not support channels");
      console.log(error);
    }
  },
  mounted() {},
  methods: {
    /*
    Checks whether the version stored in localstorage matches the newest version
    else forces a full refresh
    */
    checkRefresh: function () {
      fetch(`${Config.baseURL}/version`, {})
        .then((response) => response.json())
        .then((data) => {
          if (localStorage.getItem("version") != data.version) {
            localStorage.setItem("version", data.version);
            window.location.reload();
          }
        });
    },
  },
};
</script>

<style>
/*
body {
  background: url("./assets/bg_n3.svg"); 
  background-size: cover; 
}

*/
</style>

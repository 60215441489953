<template>
  <nav class="uk-margin uk-background-default uk-margin-remove-bottom uk-flex uk-flex-between" uk-navbar>
    <div class="max-navbar uk-navbar-left uk-width-1-1 uk-flex uk-flex-between res-navbar">
      <div class="uk-flex uk-space-between">
        <router-link class="uk-navbar-item uk-logo" to="/">
          <slot></slot>
        </router-link>
      </div>
    </div>
    <language-switcher />
  </nav>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher.vue";

export default {
  name: "RegisterNavBar",
  data() {
    return {
      role: "",
      email: "",
      initials: "",
      url: "",
      opened: window.innerWidth > 900,
    };
  },
  components: {
    LanguageSwitcher,
  },
  beforeMount() {},
  methods: {},

  mounted: function () {
    /*use event listener to listen for window changes */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}

.max-navbar {
  max-width: 1420px;
}

.profile-icon {
  width: 12px;
  stroke: #b4b4b4;
  margin-top: -2px;
  margin-left: 8px;
}

.profile-dropdown {
  border-radius: 3px;
  box-shadow: 0px 0px 3px #00000029 !important;
}

.dropdown-link:hover {
  color: #6db2ef;
}

.uk-navbar-nav {
  font-size: 12px;
}

.uk-navbar-nav > li > a {
  text-transform: capitalize;
}

.mobile-only-option {
  display: none;
}

#logout {
  border: 1px solid #b4b4b4;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 11px;

  border-radius: 6px;
  /*height: 30px;  */
  background-color: white;
}

#logout:hover {
  border-color: #6db2ef;
  color: #6db2ef !important;
}

#logout:hover .profile-icon {
  border-color: #6db2ef;
  stroke: #6db2ef !important;
  color: #6db2ef !important;
}

#logout[aria-expanded="true"] .profile-icon {
  transform: rotate(180deg);
}

.dftq-router-link {
  font-weight: 500;
  font-size: 12px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.dftq-router-link:hover {
  color: #88c0f1;
}

.router-link-active {
  font-weight: 600;

  color: #193155;
}

.res-navbar {
  flex-direction: row;
}

.menu-burger {
  display: none;
}

@media only screen and (max-width: 900px) {
  .res-navbar {
    flex-direction: column;
    width: 94vw;
  }

  .mobile-only-option {
    display: block;
  }

  .menu-burger {
    display: block;
    width: 36px;
    position: absolute;
    top: 20px;
    right: 18px;
  }

  .companylogo {
    position: absolute;
    top: 24px;
    left: 0px;
  }

  .dftq-router-link {
    min-height: 60px;
  }

  #logout {
    display: none;
  }
}
</style>

<template>
  <div v-if="visible" id="popup-container">
    <div id="dark-overlay"></div>
    <div
      id="loginbox"
      class="uk-card uk-card-default uk-card-body full uk-margin-auto uk-padding"
    >
      <h4
        class="uk-margin-remove-top dftq-font-bold uk-padding-small uk-padding-remove-right uk-padding-remove-bottom uk-padding-remove-left"
      >
        {{ headerText }}
      </h4>
      <div v-show="currentStep == 0">
        <form autocomplete="off">
          <div class="uk-margin-top dftq-form-group">
            <input
              class="uk-input dftq-input"
              id="firstname"
              v-model="user.firstname"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.firstname }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="firstname">{{
              $translate("register_popup.firstname")
            }}</label>
            <label v-show="!correct_inputs.firstname" class="error-text">{{
              $translate("register_popup.firstname_mandatory")
            }}</label>
          </div>
          <div class="uk-margin-medium-top dftq-form-group">
            <input
              class="uk-input dftq-input"
              id="lastname"
              v-model="user.lastname"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.lastname }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="lastname">{{
              $translate("register_popup.lastname")
            }}</label>
            <label v-show="!correct_inputs.lastname" class="error-text">{{
              $translate("register_popup.lastname_mandatory")
            }}</label>
          </div>

          <div
            class="uk-margin-medium-top dftq-form-group"
            v-if="!user.is_invite"
          >
            <input
              class="uk-input dftq-input"
              id="bedrijf"
              v-model="user.company"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.company }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="bedrijf">{{
              $translate("register_popup.company")
            }}</label>
            <label v-show="!correct_inputs.company" class="error-text">{{
              $translate("register_popup.company_mandatory")
            }}</label>
          </div>

          <div class="dftq-form-group uk-margin-medium-top">
            <select
              class="uk-select dftq-input"
              v-model="user.language"
              id="language"
            >
              <option
                v-for="(language, index) in languages.languages"
                :key="language"
                :value="languages.values[index]"
              >
                {{ language }}
              </option>
            </select>
            <label class="dftq-placeholder" for="language">{{
              $translate("register_popup.language")
            }}</label>
          </div>
          <div
            class="uk-margin-top uk-grid-small uk-child-width-auto uk-grid dftq-form-group"
          >
            <label class="uk-text-muted uk-padding-remove-left"
              ><input
                class="uk-checkbox"
                v-model="user.checked_voorwaarden"
                value="true"
                type="checkbox"
              /><span class="uk-text-right" style="font-size: 11px">
                {{ $translate("register_popup.conditions_agree.first") }}
                <a
                  style="font-size: 11px"
                  class="uk-text-muted"
                  href="https://www.deftiq.com/gebruikersvoorwaarden-deftiq"
                  target="_blank"
                  >{{ $translate("register_popup.conditions_agree.second") }}</a
                >
                {{ $translate("register_popup.conditions_agree.third") }}
                <a
                  style="font-size: 11px"
                  class="uk-text-muted"
                  :href="privacyPolicyUrl"
                  target="_blank"
                  >{{ $translate("register_popup.conditions_agree.fourth") }}</a
                >
                {{ $translate("register_popup.conditions_agree.fifth") }}</span
              ></label
            >
            <label
              v-show="!correct_inputs.checked_voorwaarden"
              class="uk-padding-remove-left checkbox-error-text"
              >{{ $translate("register_popup.agreed_mandatory") }}</label
            >
          </div>
        </form>
      </div>

      <div v-if="currentStep == 1">
        <p class="uk-margin-small">
          {{ $translate("register_popup.verification_code.first") }}
          <b>{{ this.user.email }}</b>
          {{ $translate("register_popup.verification_code.second") }}
        </p>
        <div class="uk-margin-top dftq-form-group">
          <input
            class="uk-input dftq-input"
            id="email_code"
            v-model.number="user.email_code"
            @keypress="validatePin"
            v-on:keypress.enter="nextStep"
            maxlength="6"
            type="number"
            :class="{ 'dftq-input-wrong': !correct_inputs.email_code }"
            placeholder=" "
          />
          <label class="dftq-placeholder" for="email_code">{{
            $translate("register_popup.code")
          }}</label>
          <label v-show="!correct_inputs.email_code" class="error-text">{{
            $translate("register_popup.incorrect_code")
          }}</label>
        </div>
      </div>

      <div v-if="currentStep == 2">
        <form autocomplete="off">
          <div class="uk-margin-top dftq-form-group">
            <input
              class="uk-input dftq-input"
              id="firstname"
              v-model="user.firstname"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.firstname }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="firstname">{{
              $translate("register_popup.firstname")
            }}</label>
            <label v-show="!correct_inputs.firstname" class="error-text">{{
              $translate("register_popup.firstname_mandatory")
            }}</label>
          </div>
          <div class="uk-margin-medium-top dftq-form-group">
            <input
              class="uk-input dftq-input"
              id="lastname"
              v-model="user.lastname"
              v-on:keypress.enter="nextStep"
              type="text"
              :class="{ 'dftq-input-wrong': !correct_inputs.lastname }"
              placeholder=" "
            />
            <label class="dftq-placeholder" for="lastname">{{
              $translate("register_popup.lastname")
            }}</label>
            <label v-show="!correct_inputs.lastname" class="error-text">{{
              $translate("register_popup.lastname_mandatory")
            }}</label>
          </div>

          <div class="dftq-form-group uk-margin-medium-top">
            <select
              class="uk-select dftq-input"
              v-model="user.language"
              id="language"
            >
              <option
                v-for="(language, index) in languages.languages"
                :key="language"
                :value="languages.values[index]"
              >
                {{ language }}
              </option>
            </select>
            <label class="dftq-placeholder" for="language">{{
              $translate("register_popup.language")
            }}</label>
          </div>
          <div
            class="uk-margin-top uk-grid-small uk-child-width-auto uk-grid dftq-form-group"
          >
            <label class="uk-text-muted uk-padding-remove-left"
              ><input
                class="uk-checkbox"
                v-model="user.checked_voorwaarden"
                value="true"
                type="checkbox"
              /><span class="uk-text-right" style="font-size: 11px">
                {{ $translate("register_popup.conditions_agree.first") }}
                <a
                  style="font-size: 11px"
                  class="uk-text-muted"
                  href="https://www.deftiq.com/gebruikersvoorwaarden-deftiq"
                  target="_blank"
                  >{{ $translate("register_popup.conditions_agree.secnd") }}</a
                >
                {{ $translate("register_popup.conditions_agree.third") }}
                <a
                  style="font-size: 11px"
                  class="uk-text-muted"
                  :href="privacyPolicyUrl"
                  target="_blank"
                  >{{ $translate("register_popup.conditions_agree.fourth") }}</a
                >
                {{ $translate("register_popup.conditions_agree.fifth") }}</span
              ></label
            >
            <label
              v-show="!correct_inputs.checked_voorwaarden"
              class="uk-padding-remove-left checkbox-error-text"
              >{{ $translate("register_popup.agreed_mandatory") }}</label
            >
          </div>
        </form>
      </div>
      <button
        class="uk-button uk-button-primary uk-width-1-1 dftq-button uk-margin-medium-top"
        @click="nextStep"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
//import Config from '../config/config.js'
//import {updateLogo} from "../config/deftiq.js";
import { getAvailableLanguages } from "../config/deftiq.js";
import Config from "../config/config.js";
import { useToast } from "vue-toastification";

export default {
  name: "RegisterPopupForm",
  data() {
    return {
      user: {
        firstname: "",
        lastname: "",
        email_code: "",
        active: true,
        language: "nl",
        company: "",
        email: "",
        is_invite: false,
        checked_voorwaarden: false,
      },
      correct_inputs: {
        firstname: true,
        lastname: true,
        email_code: true,
        language: true,
        company: true,
        email: true,
        checked_voorwaarden: true,
      },
      visible: !this.$store.getters.isVerified,
      currentStep: 0,
      languages: getAvailableLanguages(),
    };
  },
  computed: {
    headerText: function () {
      let text = "";
      switch (this.currentStep) {
        case 0:
          text = this.$translate("register_popup.fill_in_info");
          break;
        case 1:
          text = this.$translate("register_popup.verify_email");
          break;
        case 2:
          text = this.$translate("register_popup.check_info");
          break;
      }
      return text;
    },
    buttonText: function () {
      //case 0 should display volgende or registreer depending on presence of selected courses with deelnemer autorisatie
      let text = "";
      switch (this.currentStep) {
        case 0:
          text = this.$translate("register_popup.register");
          break;
        case 2:
          text = this.$translate("register_popup.register");
          break;
        case 1:
          text = this.$translate("register_popup.verify");
          break;
      }
      return text;
    },
    isVerified() {
      return this.$store.getters.isVerified;
    },
    privacyPolicyUrl: function () {
      return this.$store.state.language != "nl"
        ? "https://www.deftiq.com/privacy-policy-deftiq-engels"
        : "https://www.deftiq.com/privacy-policy-deftiq";
    },
  },
  watch: {
    isVerified(newValue) {
      this.visible = !newValue;
      let user = this.$store.getters.getUser;
      this.loadUserInfo();
      this.user.is_invite = user.registration_type == "invite";
      this.currentStep =
        (user.firstname == "voornaam" && user.lastname == "achternaam") ||
        this.user.is_invite
          ? 0
          : 1;
    },
  },
  beforeMount() {},
  methods: {
    nextStep: function () {
      switch (this.currentStep) {
        case 0:
          this.updateUser();
          break;
        case 1:
          this.verifyEmail();
          break;
      }
    },
    loadUserInfo: function () {
      fetch(`${Config.baseURL}/user/show/0`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "good") {
            if (this.user.is_invite) {
              this.user.firstname = data.user.firstname;
              this.user.lastname = data.user.lastname;

              this.user.language = data.user.lang;
            }

            this.user.email = data.user.email;
          }
        });
    },
    validateInputs: function () {
      this.correct_inputs.firstname = this.user.firstname.length != 0;
      this.correct_inputs.lastname = this.user.lastname.length != 0;
      this.correct_inputs.company =
        this.user.company.length != 0 || this.user.is_invite;

      this.correct_inputs.checked_voorwaarden = this.user.checked_voorwaarden;
      return Object.values(this.correct_inputs).every((e) => e === true);
    },
    updateUser: function () {
      //check if all fields were entered

      //check if user has courses which need email verification
      if (!this.validateInputs()) {
        return;
      }

      let params = new FormData();
      params.append("firstname", this.user.firstname);
      params.append("lastname", this.user.lastname);
      params.append("language", this.user.language);
      if (!this.user.is_invite) {
        params.append(
          "metadata",
          JSON.stringify({ company: this.user.company })
        );
      }

      params.append("code", this.user.email_code);
      let endpoint = this.user.is_invite
        ? "/invite/update"
        : "/register/update";

      fetch(`${Config.baseURL + endpoint}`, {
        method: "POST",
        body: params,
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": this.$store.state.csrf_token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "good") {
            if (!this.user.is_invite) {
              this.currentStep = 1;
            } else {
              this.visible = false;
              this.$store.commit("updateUserField", {
                field: "email_verify_code",
                value: null,
              });
            }
          } else {
            //foutmelding geven
          }
        });

      const supported_languages = {
        nl: true,
        en: true,
        fr: true,
        es: true,
        de: true,
      };
      //switch the language
      let language =
        supported_languages[this.user.language] == undefined
          ? "en"
          : this.user.language;

      /*
      if (sessionStorage.getItem("user_role") != "leerling") {
        // eslint-disable-next-line no-undef
        Weglot.switchTo(language);
      }
      */
      sessionStorage.setItem("lang", language);
    },
    verifyEmail: function () {
      let params = new FormData();

      params.append("code", this.user.email_code);
      fetch(`${Config.baseURL}/public/register/verify`, {
        method: "POST",
        body: params,
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": this.$store.state.csrf_token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "good") {
            this.correct_inputs.email_code = true;
            this.visible = false;
            this.$store.commit("updateUserField", {
              field: "email_verify_code",
              value: null,
            });
            const toast = useToast();
            toast(this.$translate("register_popup.email_verified"));

            if (sessionStorage.getItem("user_role") == "beheerder") {
              this.$store.commit("startBeheerderTour", true);
            }
          } else {
            this.correct_inputs.email_code = false;
          }
        });
    },
  },

  mounted: function () {
    /*use event listener to listen for window changes */
  },
};
</script>

<style scoped>
#dark-overlay {
  background-color: black;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0.4;
}

#loginbox {
  box-shadow: 0px 0px 10px #00000012;
  position: absolute;
  left: 36%;
  right: 36%;
  width: 28%;
  top: 120px;

  border-radius: 4px;
  max-width: 400px;
  z-index: 999;
}

@media only screen and (max-width: 1320px) {
  #loginbox {
    width: 100%;
    left: 0px;
    right: 20px;
  }
}
</style>

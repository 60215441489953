import Config from "./config.js";
import Bowser from "bowser";
import { useToast } from "vue-toastification";

const dftq_languages = [
  "Bulgaars",
  "Deens",
  "Duits",
  "Engels",
  "Ests",
  " Fins",
  "Frans",
  "Grieks",
  "Hongaars",
  "Italiaans",
  "Lets",
  "Litouws",
  "Maltees",
  "Nederlands",
  "Pools",
  "Portugees",
  "Roemeens",
  "Sloveens",
  "Slowaaks",
  "Spaans",
  "Tsjechisch",
  "Zweeds",
  "Turks",
];
const dftq_localized_languages = [
  "Български",
  "Dansk",
  "Deutsch",
  "English",
  "Eesti keel",
  "Suomi",
  "Français",
  "ελληνικά",
  "Magyar",
  "Italiano",
  "Latviešu valoda ",
  "Lietuvių kalba",
  "Malti",
  "Nederlands",
  "Polski",
  "Português",
  "Limba română ",
  "Slovenščina",
  "Slovenčina",
  "Español",
  "čeština ",
  "Svenska",
  "Türkçe",
];
const dftq_languages_short = [
  "bg",
  "da",
  "de",
  "en",
  "est",
  "fi",
  "fr",
  "el",
  "hu",
  "it",
  "lv",
  "lt",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "sl",
  "sk",
  "es",
  "cs",
  "sv",
  "tr",
];
const dftq_platform_languages = {
  nl: true,
  en: true,
  es: true,
  de: true,
  pt: true,
  tr: true,
  bg: true,
  fr: true,
};

function checkValidBrowser(route = "", is_course = false) {
  const language = localStorage.getItem("dftq-lang");
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    // declare browsers per OS
    windows: {
      edge: ">=119",
    },
    macos: {
      safari: ">=17",
    },

    // per platform (mobile, desktop or tablet)
    mobile: {
      safari: ">=17",
      "android browser": ">3.10",
      samsung_internet: ">=15.0",
      firefox: "<=20",
    },

    // or in general
    chrome: ">=118",
    firefox: ">=119",
  });
  /*

  const isFireFoxMobile = browser.satisfies({
    mobile: {
      'firefox':  ">=60"
    },
  })

  if(isFireFoxMobile){
    const toast = useToast();
    toast.warning("Gebruik een andere browser voor een optimale weergave van het online platform.")
  }
  */

  let course_text =
    language == "nl"
      ? "Let op de huidige browser kan ervoor zorgen dat de online training niet optimaal functioneert."
      : "Warning: your current browser can cause issues with the online course.";
  let default_text =
    language == "nl"
      ? "Gebruik een andere browser voor een optimale weergave van het online platform"
      : "Warning: Use a different browser to use our platform optimally.";
  if (!isValidBrowser) {
    const toast = useToast();
    let text = is_course ? course_text : default_text;
    if (route != "Training" || is_course) {
      toast.warning(text, { timeout: 8000 });
    }
  }
}

function getAvailableLanguages(localize = false, platform_only = false) {
  let language_names = [];
  let language_shortnames = [];

  if (platform_only) {
    for (let i = 0; i < dftq_languages.length; i++) {
      if (dftq_platform_languages[dftq_languages_short[i]]) {
        language_names.push(
          localize ? dftq_localized_languages[i] : dftq_languages[i]
        );
        language_shortnames.push(dftq_languages_short[i]);
      }
    }
  } else {
    language_shortnames = dftq_languages_short;
    language_names = localize ? dftq_localized_languages : dftq_languages;
  }

  return {
    languages: language_names,
    values: language_shortnames,
  };
}

function loadLanguageOptions(id) {
  let select = document.getElementById(id);

  for (let i = 0; i < dftq_languages.length; i++) {
    let opt = document.createElement("option");
    opt.value = dftq_languages_short[i];
    opt.classList.add("wg-exclude");
    if (opt.value == "nl") {
      opt.selected = true;
    }
    opt.innerHTML = dftq_languages[i];
    select.appendChild(opt);
  }
}

//take expiry time as argument
function prepareLogin(expiry, user_role) {
  let redirect = "";
  if (user_role == "leerling") {
    redirect = "/deelnemer/trainingen";
  } else if (user_role == "beheerder" || user_role == "admin") {
    redirect = "/dashboard";
  }

  //session will last 6 hours
  //max-age=21600 removed
  document.cookie = "redirect=" + redirect + ";SameSite=Strict;path=/";
  document.cookie = "logged_in=1;max-age=43200;SameSite=Strict;path=/";
  document.cookie = "logged_in_session=1;SameSite=Strict;path=/";

  //track the time until we trigger a logout
  sessionStorage.setItem("session_expires_at", 43200 + Date.now() / 1000);
  //send message using Browserchannel api to share logged in between tabs

  //store tab identifier
  /* 
  We create a random identifier and store it in the sessionstorage 
  We reject messages send on the channel if our identifier is the same. 

  */
  let identifier = `dftq-${Math.floor(Math.random() * 1000)}`;
  sessionStorage.setItem("window_identifier", identifier);

  try {
    const channel = new BroadcastChannel("deftiq");
    channel.postMessage({
      event: "logged_in",
      role: user_role,
      id: identifier,
    });
    channel.close();
  } catch (error) {
    console.error(error);
  }
}

function triggerAnalyticsEvents(events, trigger) {
  if (sessionStorage.getItem("user_role") == "admin") {
    return;
  }

  const event_list = events.split(",");
  for (let event of event_list) {
    // eslint-disable-next-line no-undef
    fbq("trackCustom", trigger + " - " + event);

    //if (this.$gtm.enabled()) {
    window.dataLayer.push({
      event: trigger + " - " + event,
      category: "App.deftiq.com",
    });
    //}
  }
}

function prepareLogout() {
  sessionStorage.clear();
  document.cookie =
    "logged_in=0;expires=Thu, 18 Dec 2013 12:00:00 UTC;SameSite=Strict;path=/";
  document.cookie =
    "redirect=0;expires=Thu, 18 Dec 2013 12:00:00 UTC;SameSite=Strict;path=/";
  document.cookie =
    "logged_in_session=0;expires=Thu, 18 Dec 2013 12:00:00 UTC;SameSite=Strict;path=/";

  //send message to other tabs if opened to close
  try {
    const channel = new BroadcastChannel("deftiq");
    channel.postMessage({ event: "logged_out" });
    channel.close();
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
}

/*
function addOptions(id,optionlist,propname){
  let select = document.getElementById(id);
  for(option of optionlist){
    let opt = document.createElement('option');
    opt.value = option[propname]; 
    opt.innerHTML = option[propname]; 
    select.appendChild(opt);
  }

 }
*/

function getInput(id) {
  if (document.getElementById(id) === null) {
    return "";
  } else {
    return document.getElementById(id).value;
  }
}

function setInput(id, value) {
  if (document.getElementById(id) === null) {
    return null;
  } else {
    return (document.getElementById(id).value = value);
  }
}

function getQueryParams() {
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  return urlParams;
}

function updateColor(classname, primary_color, secondary_color) {
  let elements = document.getElementsByClassName(classname);
  for (let element of elements) {
    element.style.backgroundColor = primary_color;
    element.style.color = secondary_color;
  }
}

function updateLogo(imageSource) {
  if (document.getElementsByClassName("companylogo").length != 0) {
    document.getElementsByClassName("companylogo")[0].src =
      Config.baseURL + "/images/" + imageSource;
  }
}

/*

function txt(id, text) {
  if (document.getElementById(id) === null) {
  } else {
    document.getElementById(id).innerText = text;
  }
}

function identify(token) {
  if (sessionStorage.getItem("company_logo") != null) {
    return Promise.resolve({
      company_logo: sessionStorage.getItem("company_logo"),
      primary_color: sessionStorage.getItem("primary_color"),
      secondary_color: sessionStorage.getItem("secondary_color"),
      id: sessionStorage.getItem("id"),
      email: sessionStorage.getItem("email"),
      user_role: sessionStorage.getItem("user_role"),
    });
  } else {
    //fill the session values
    fetch(`${Config.baseURL}/user/identify`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        for (let [key, value] of Object.entries(data)) {
          sessionStorage.setItem(key, value);
        }
      });
  }
  return fetch(`${Config.baseURL}/user/identify`, {
    credentials: "include",
  }).then((response) => response.json());
}

function connectToWebflowButton(id, event, func) {
  let element = document.getElementById(id);
  if (element != null && typeof element != "undefined") {
    element.addEventListener(event, func);
  }
}

*/

//sorting function
function getSortOrder(prop, direction) {
  return function (a, b) {
    if (isNaN(a[prop])) {
      if (String(a[prop]).toUpperCase() > String(b[prop]).toUpperCase()) {
        return 1 * direction;
      } else if (
        String(a[prop]).toUpperCase() < String(b[prop]).toUpperCase()
      ) {
        return -1 * direction;
      }
      return 0;
    }

    if (a[prop] > b[prop]) {
      return 1 * direction;
    } else if (a[prop] < b[prop]) {
      return -1 * direction;
    }
    return 0;
  };
}

/*
function personalizeWebflow(token) {
  identify(token).then((personals) => {
    updateColor("personalize", personals.primary_color, personals.secondary_color);
    updateLogo(personals.company_logo);
  });
}
*/

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export {
  dftq_platform_languages,
  triggerAnalyticsEvents,
  prepareLogin,
  prepareLogout,
  checkValidBrowser,
  updateColor,
  getAvailableLanguages,
  updateLogo,
  getInput,
  getQueryParams,
  setInput,
  getSortOrder,
  loadLanguageOptions,
  getCookie,
};

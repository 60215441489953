<template>
  <div
    class="tw-block tw-float-right tw-mt-4 tw-mr-3 tw-w-min-96"
    :class="{ 'tw-top-0 tw-absolute tw-right-1': floating }"
  >
    <div class="dftq-form-group">
      <select
        class="dftq-input tw-h-9 tw-px-2 tw-font-sans tw-text-[#666] custom-select tw-outline-none tw-bg-white tw-border-gray-200"
        @change="updateLanguage"
        v-model="language"
      >
        <option class="tw-font-sans" value="en">English</option>
        <option class="tw-font-sans" value="fr">Français</option>
        <option class="tw-font-sans" value="de">Deutsch</option>
        <option class="tw-font-sans" value="es">Español</option>
        <option class="tw-font-sans" value="nl">Nederlands</option>
        <option class="tw-font-sans" value="tr">Türkçe</option>
        <option class="tw-font-sans" value="pt">Português</option>
        <option class="tw-font-sans" value="bg">Български</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: function () {
    return {
      language:
        localStorage.getItem("wglang") != null
          ? localStorage.getItem("wglang")
          : "nl",
    };
  },
  mounted() {
    this.findBrowserLanguage();
  },
  props: {
    floating: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    findBrowserLanguage: function () {
      let languages = ["en", "fr", "de", "es", "nl", "bg", "tr", "pt"];
      let browserLanguage = "en";
      for (let language of languages) {
        if (window.navigator.language.includes(language)) {
          browserLanguage = language;
          break;
        }
      }
      this.language =
        localStorage.getItem("dftq-lang") != null
          ? localStorage.getItem("dftq-lang")
          : browserLanguage;
      this.updateLanguage();
      // eslint-disable-next-line no-undef
      //Weglot.switchTo(browserLanguage);
    },
    whenAvailable: function () {
      /*
      var interval = 100; // ms
      window.setTimeout(() => {
        // eslint-disable-next-line no-undef
        if (Weglot.initialized && Weglot.getCurrentLang() == this.language) {
          callback();
        } else {
          this.whenAvailable(callback);
        }
      }, interval);
      */
    },
    updateLanguage: function () {
      this.$store.commit("updateLanguage", this.language);
      localStorage.setItem("dftq-lang", this.language);
      // eslint-disable-next-line no-undef
      //Weglot.switchTo(this.language);
    },
  },
};
</script>

<style scoped>
.custom-select {
  appearance: none;

  padding-right: 24px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23B4B4B4' d='M12 1 9 6h6zM12 13 9 8h6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
}
</style>

<template>
  <div
    class="tw-cursor-pointer tw-bg-yellow-500 tw-px-12 tw-py-4 tw-absolute tw-z-50 tw-bottom-2 tw-left-2 tw-rounded tw-shadow-lg tw-opacity-80 hover:tw-opacity-90 tw-transition-all tw-flex tw-justify-center tw-items-center"
    @click="clicked = true"
    v-if="getStatus()"
  >
    <h2 class="tw-text-yellow-200 tw-font-semibold tw-text-xl tw-p-0 tw-m-0">
      testomgeving
    </h2>
  </div>
</template>

<script>
export default {
  name: "DevelopmentTip",
  data() {
    return {
      clicked: false,
    };
  },
  emits: [],
  props: {},
  computed: {},
  watch: {},
  methods: {
    getStatus: function () {
      if (process.env.VUE_APP_DEV_TIP == "YES" && !this.clicked) return true;
      return false;
    },
  },
};
</script>
<style scoped></style>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { getCookie } from "../config/deftiq.js";

const routes = [
  {
    path: "/error/:type",
    name: "ErrorPage",
    component: () =>
      import(/* webpackChunkName: "errorpage" */ "../views/Error.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/maintenance",
    name: "MaintenancePage",
    component: () =>
      import(
        /* webpackChunkName: "errorpage" */ "../views/landingpaginas/MaintenancePage.vue"
      ),
    meta: { isPublic: true },
  },
  {
    path: "/:id?",
    name: "Home",
    component: Home,
    meta: { isPublic: true },
  },
  {
    path: "/login/:id?",
    name: "LoginHome",
    component: Home,
    meta: { isPublic: true },
  },
  //route for external integrations like bouwpas
  {
    path: "/launch/:token/:integration/:integration_payload*",
    name: "Launch",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: { isPublic: true },
  },
  //route for registration of beheerders and direct access

  {
    path: "/register/:token?",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/sign-up/:role",
    name: "Signup",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/RegisterNoCourse.vue"
      ),
    meta: { isPublic: true },
  },
  {
    path: "/invite",
    name: "Invite",
    component: () =>
      import(/* webpackChunkName: "invitepage" */ "../views/Invite.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/deelnemer/trainingen",
    name: "Mijn Trainingen",
    component: () =>
      import(
        /* webpackChunkName: "cursusoverzicht" */ "../views/deelnemer/Cursusoverzicht.vue"
      ),
  },
  {
    path: "/deelnemer/resultaten",
    name: "Mijn Resultaten",
    component: () =>
      import(
        /* webpackChunkName: "results" */ "../views/deelnemer/Resultaten.vue"
      ),
  },
  {
    path: "/deelnemer/training/evaluatie",
    name: "Mijn evaluatie",
    component: () =>
      import(
        /* webpackChunkName: "results" */ "../views/deelnemer/Evaluatie.vue"
      ),
  },
  {
    path: "/deelnemer/training/richtext",
    name: "RichTextView",
    component: () =>
      import(
        /* webpackChunkName: "results" */ "../views/deelnemer/RichText.vue"
      ),
  },
  {
    path: "/profiel",
    name: "Mijn Profiel",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "../views/deelnemer/Profiel.vue"
      ),
  },
  {
    path: "/deelnemer/training",
    name: "Training",
    component: () =>
      import(
        /* webpackChunkName: "training" */ "../views/deelnemer/Training.vue"
      ),
  },
  {
    path: "/deelnemer/training/quiz/resultaten",
    name: "QuizResultaten",
    component: () =>
      import(
        /* webpackChunkName: "quizresults" */ "../views/deelnemer/quiz/QuizResultaten.vue"
      ),
  },
  {
    path: "/deelnemer/training/quiz",
    name: "Quiz",
    component: () =>
      import(/* webpackChunkName: "quiz" */ "../views/deelnemer/quiz/Quiz.vue"),
  },

  {
    path: "/deelnemer/training/quiz/feedback",
    name: "Quizfeedback",
    component: () =>
      import(
        /* webpackChunkName: "quizfeedback" */ "../views/deelnemer/quiz/QuizFeedback.vue"
      ),
  },

  //admin & beheerderpages
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },

  {
    path: "/deelnemers",
    name: "Deelnemers",
    component: () =>
      import(
        /* webpackChunkName: "participant_overview" */ "../views/Deelnemersoverzicht.vue"
      ),
  },
  {
    path: "/administratie",
    name: "Administratie",
    component: () =>
      import(
        /* webpackChunkName: "administration_overview" */ "../views/Administratieoverzicht.vue"
      ),
  },
  {
    path: "/administratie/boekingen/wijzigen",
    name: "Boeking wijzigen",
    component: () =>
      import(
        /* webpackChunkName: "update_booking" */ "../views/booking/UpdateBooking.vue"
      ),
  },
  {
    path: "/administratie/boekingen/bevestigen",
    name: "Boeking bevestigen",
    component: () =>
      import(
        /* webpackChunkName: "confirm_booking" */ "../views/booking/ConfirmBooking.vue"
      ),
  },
  {
    path: "/deelnemers/profiel",
    name: "DeelnemersProfiel",
    component: () =>
      import(
        /* webpackChunkName: "participant_profile" */ "../views/DeelnemersProfiel.vue"
      ),
  },
  {
    path: "/deelnemers/training/quiz/feedback",
    name: "QuizfeedbackBeheerder",
    component: () =>
      import(
        /* webpackChunkName: "quizfeedback" */ "../views/deelnemer/quiz/QuizFeedback.vue"
      ),
  },
  {
    path: "/trainingen",
    name: "Trainingen",
    component: () =>
      import(
        /* webpackChunkName: "training_overview" */ "../views/Trainingsoverzicht.vue"
      ),
  },
  {
    path: "/extern/trainingen",
    name: "ExterneTrainingen",
    component: () =>
      import(
        /* webpackChunkName: "external_training_overview" */ "../views/administration/external/ExternTrainingsOverzicht.vue"
      ),
  },
  {
    path: "/extern/trainingen/training",
    name: "ExterneTraining",
    component: () =>
      import(
        /* webpackChunkName: "external_training_single" */ "../views/administration/external/ExternTraining.vue"
      ),
  },
  {
    path: "/totaal",
    name: "TrainingenTotaal",
    component: () =>
      import(
        /* webpackChunkName: "training_complete_overview" */ "../views/Totaaloverzicht.vue"
      ),
  },
  {
    path: "/reports/aanmaken",
    name: "RapportAanmaken",
    component: () =>
      import(
        /* webpackChunkName: "rapport_aanmaken" */ "../views/tools/ReportBuilder.vue"
      ),
  },
  {
    path: "/reports/bekijken",
    name: "RapportBekijken",
    component: () =>
      import(
        /* webpackChunkName: "rapport_aanmaken" */ "../views/tools/ReportViewer.vue"
      ),
  },
  {
    path: "/flows/aanmaken",
    name: "FlowAanmaken",
    component: () =>
      import(
        /* webpackChunkName: "flow_aanmaken" */ "../views/flows/CreateFlow.vue"
      ),
  },
  {
    path: "/flows",
    name: "FlowOverzicht",
    component: () =>
      import(
        /* webpackChunkName: "flow_overzicht" */ "../views/flows/Flowoverzicht.vue"
      ),
  },
  {
    path: "/flows/flow",
    name: "Flow",
    component: () =>
      import(/* webpackChunkName: "flow_view" */ "../views/flows/Flow.vue"),
  },
  {
    path: "/flows/booking",
    name: "FlowBookingPage",
    component: () =>
      import(
        /* webpackChunkName: "flow_booking_page" */ "../views/flows/FlowBookingPage.vue"
      ),
  },
  {
    path: "/trainingen/vragenpool",
    name: "Vragenpool",
    component: () =>
      import(/* webpackChunkName: "question_pool" */ "../views/Vragenpool.vue"),
  },
  {
    path: "/trainingen/catalogus",
    name: "TrainingenCatalogus",
    component: () =>
      import(
        /* webpackChunkName: "training_catalogue" */ "../views/Catalogus.vue"
      ),
  },
  {
    path: "/trainingen/catalogus/webflow/:sector?",
    name: "TrainingenCatalogusWebflow",
    component: () =>
      import(
        /* webpackChunkName: "training_catalogue" */ "../views/Catalogus.vue"
      ),
    meta: { isPublic: true },
  },
  {
    path: "/training/overzicht",
    name: "Trainingsoverzicht",
    component: () =>
      import(/* webpackChunkName: "admin_training" */ "../views/Training.vue"),
  },
  {
    path: "/training/deelnemer/inzichten",
    name: "DeelnemerTrainingInzichten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training" */ "../views/DeelnemerTrainingInzichten.vue"
      ),
  },
  {
    path: "/admin/training/wijzigen",
    name: "Training updaten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training_update" */ "../views/TrainingUpdaten.vue"
      ),
  },
  {
    path: "/admin/training/vragen/wijzigen",
    name: "Training vragen updaten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training_question_update" */ "../views/adaptive/QuestionMatrix.vue"
      ),
  },
  {
    path: "/admin/training/quiz/wijzigen",
    name: "Quiz updaten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training_quiz_update" */ "../views/QuizUpdaten.vue"
      ),
  },
  {
    path: "/admin/training/richtext/wijzigen",
    name: "Rich Text updaten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training_quiz_update" */ "../views/RichTextUpdaten.vue"
      ),
  },

  {
    path: "/admin/training/activiteit/wijzigen",
    name: "Activity updaten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training_rise_update" */ "../views/RiseUpdaten.vue"
      ),
  },
  {
    path: "/admin/training/evaluatie/wijzigen",
    name: "Evaluatie updaten",
    component: () =>
      import(
        /* webpackChunkName: "admin_training_eval_update" */ "../views/EvalUpdaten.vue"
      ),
  },
  {
    path: "/admin/bedrijven",
    name: "Bedrijven",
    component: () =>
      import(
        /* webpackChunkName: "companies_overview" */ "../views/Bedrijfsoverzicht.vue"
      ),
  },
  {
    path: "/admin/bedrijven/profiel",
    name: "Bedrijfsprofiel",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/Bedrijf.vue"),
  },
  {
    path: "/admin/bedrijven/profiel/instellingen",
    name: "Bedrijfsprofiel-instellingen",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "../views/BedrijfInstellingen.vue"
      ),
  },
  {
    path: "/admin/bedrijven/registratie",
    name: "CustomInputEditor",
    component: () =>
      import(
        /* webpackChunkName: "custom_input" */ "../views/admin/CustomInputEditor.vue"
      ),
  },
  {
    path: "/administratie/contract/",
    name: "ContractPage",
    component: () =>
      import(
        /* webpackChunkName: "single_contract" */ "../views/administration/Contract.vue"
      ),
  },
  {
    path: "/administratie/inkoop/",
    name: "PurchaseOrderPage",
    component: () =>
      import(
        /* webpackChunkName: "single_contract" */ "../views/administration/PurchaseOrder.vue"
      ),
  },
  {
    path: "/proctoring",
    name: "ProctorOverviewPage",
    component: () =>
      import(
        /* webpackChunkName: "single_contract" */ "../views/proctoring/ProctorOverview.vue"
      ),
  },
  {
    path: "/proctoring/view/:userid/:activity_id/:attempt",
    name: "ProctorViewPage",
    component: () =>
      import(
        /* webpackChunkName: "single_contract" */ "../views/proctoring/ProctorViewer.vue"
      ),
  },
  {
    path: "/skills",
    name: "SkillOverviewPage",
    component: () =>
      import(
        /* webpackChunkName: "single_contract" */ "../views/SkillOverzicht.vue"
      ),
  },
  //landingpages
  {
    path: "/landingpage/ore",
    name: "Landingpage-ore",
    component: () =>
      import(
        /* webpackChunkName: "ore_landingpage" */ "../views/landingpaginas/oreLandingPage.vue"
      ),
    meta: { isPublic: true },
  },
  {
    path: "/landingpage/sir",
    name: "Landingpage-sir",
    component: () =>
      import(
        /* webpackChunkName: "sir_landingpage" */ "../views/landingpaginas/sirLandingPage.vue"
      ),
    meta: { isPublic: true },
  },
  {
    path: "/booking/:category?/:courseid?",
    name: "booking-page",
    component: () =>
      import(
        /* webpackChunkName: "booking_external_page" */ "../views/booking/BookingPage.vue"
      ),
    meta: { isPublic: true },
  },
  {
    path: "/tools/bookinglink",
    name: "booking-tool-page",
    component: () =>
      import(
        /* webpackChunkName: "tool_page_booking" */ "../views/tools/BookingLinkGenerator.vue"
      ),
    meta: { isPublic: false },
  },
  {
    path: "/tools/incident",
    name: "booking-incident-page",
    component: () =>
      import(
        /* webpackChunkName: "tool_page_incident" */ "../views/tools/IncidentManagement.vue"
      ),
    meta: { isPublic: false },
  },
  {
    path: "/tools/marketing",
    name: "marketing-tools-page",
    component: () =>
      import(
        /* webpackChunkName: "tool_page_marketing" */ "../views/tools/EmailMarketing.vue"
      ),
    meta: { isPublic: false },
  },
  {
    path: "/tools/evaluations",
    name: "evaluations-page",
    component: () =>
      import(
        /* webpackChunkName: "tool_page_evaluation" */ "../views/tools/Evaluaties.vue"
      ),
    meta: { isPublic: false },
  },
  {
    path: "/tools/apollo",
    name: "mboxconverter-page",
    component: () =>
      import(
        /* webpackChunkName: "tool_page_marketing" */ "../views/tools/MboxConverter.vue"
      ),
    meta: { isPublic: false },
  },
  {
    path: "/tools/evaluations/view",
    name: "view-evaluation-page",
    component: () =>
      import(
        /* webpackChunkName: "tool_page_evaluation_view" */ "../views/tools/Evaluatie.vue"
      ),
    meta: { isPublic: false },
  },
  {
    path: "/tools",
    name: "tool-page",
    component: () =>
      import(/* webpackChunkName: "tool_page" */ "../views/tools/Tools.vue"),
    meta: { isPublic: false },
  },

  {
    path: "/intern/booking",
    name: "booking-internal-page",
    component: () =>
      import(
        /* webpackChunkName: "booking_page" */ "../views/booking/BookingPage.vue"
      ),
  },
  {
    path: "/exams/edit",
    name: "exam-edit-page",
    component: () =>
      import(
        /* webpackChunkName: "exam_edit_page" */ "../views/booking/exams/ExamEditor.vue"
      ),
  },
  {
    path: "/exams",
    name: "exam-page",
    component: () =>
      import(
        /* webpackChunkName: "exam_page" */ "../views/booking/exams/ExamBookingPage.vue"
      ),
  },

  {
    path: "/terms",
    name: "terms-page",
    component: () =>
      import(
        /* webpackChunkName: "terms_page" */ "../views/booking/terms/Terms.vue"
      ),
  },
  {
    path: "/terms/editor/",
    name: "terms-editor-page",
    component: () =>
      import(
        /* webpackChunkName: "terms_page" */ "../views/booking/terms/TermsEditor.vue"
      ),
  },
  {
    path: "/terms/viewer/",
    name: "terms-viewer-page",
    component: () =>
      import(
        /* webpackChunkName: "terms_viewer_page" */ "../views/booking/terms/TermsViewer.vue"
      ),
  },
  {
    path: "/mails",
    name: "mails-selector-page",
    component: () =>
      import(
        /* webpackChunkName: "terms_viewer_page" */ "../views/mail/Emailoverzicht.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
/* eslint-disable no-unused-vars */
router.beforeEach(async (to, from) => {
  /*during maintenance mode */
  /*
  getCookie('logged_in')
  if(to.name != "MaintenancePage"){
    return {name: 'MaintenancePage'}
  }
  */

  if (
    (getCookie("logged_in") != "1" || getCookie("logged_in_session") != "1") &&
    !to.meta.isPublic
  ) {
    return { name: "Home" };
  }
});

/* eslint-enable no-unused-vars */

export default router;
